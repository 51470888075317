import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { ActivityList } from '../models/activitiesList.model';
import { Employee } from '../models/employee.model';
import { EmployeeAvailability } from '../models/employeeAvailability';
import { EmployeeData } from '../models/employeeData.model';
import { EmployeeList } from '../models/EmployeeList.model';
import { UpdatedEmployee } from '../models/updatedEmployee.model';

export const EmployeesActions = createActionGroup({
    source: 'Employees',
    events: {
        'Load Employees': emptyProps(),
        'Load Employees Success': props<{ employees: EmployeeList }>(),
        'Load Employees Failure': props<{ message: any }>(),

        'Get Employee By Id': props<{ id: string }>(),
        'Get Employee By Id Success': props<{ employee: Employee }>(),
        'Get Employee By Id Failure': props<{ message: any }>(),

        'Set Employee': props<{ employee: Employee }>(),
        'Update Employee Availability': props<{ employeeAvailability: EmployeeAvailability }>(),
        'Update Employee Activities': props<{ employeeActivities: ActivityList }>(),

        'Update Employee': props<{ employee: UpdatedEmployee }>(),
        'Update Employee Success': props<{ employee: UpdatedEmployee }>(),
        'Update Employee Failure': emptyProps(),

        'Update Employee Data': props<{ employee: EmployeeData }>(),
        'Update Employee Data Success': props<{ employee: EmployeeData }>(),
        'Update Employee Data Failure': emptyProps(),

        'Get Employee Availability By Id': props<{ id: string }>(),
        'Get Employee Availability By Id Success': props<{ employeeAvailability: EmployeeAvailability }>(),
        'Get Employee Availability By Id Failure': props<{ message: any }>(),

        'Update Employee Availability By Id': props<{ employeeAvailability: EmployeeAvailability }>(),
        'Update Employee Data Availability By Id Success': props<{
            employeeAvailability: EmployeeAvailability;
        }>(),
        'Update Employee Data Availability By Id Failure': emptyProps(),
    },
});
